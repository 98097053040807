import 'angular-gettext';
import * as moment from 'moment';
import { assign, defaultTo, findIndex, get, map, pullAllBy, reduce, toNumber } from 'lodash/fp';
import { StateParams } from '@uirouter/core';
import accounts, { AccountsService } from '../../common/accounts/accounts.service';
import api, { ApiService } from '../../common/api/api.service';
import contacts, { ContactsService } from '../../contacts/contacts.service';
import designationAccounts, {
    DesignationAccountsService,
} from '../../common/designationAccounts/designationAccounts.service';
import donations, { DonationsService } from './donations.service';
import joinComma from '../../common/fp/joinComma';
import locale, { LocaleService } from '../../common/locale/locale.service';
import serverConstants, { ServerConstantsService } from '../../common/serverConstants/serverConstants.service';
import uiRouter from '@uirouter/angularjs';

export class DonationsController {
    data: any;
    enableNext: boolean;
    endDate: moment.Moment;
    inContact: boolean;
    listLoadCount: number;
    loading: boolean;
    meta: any;
    nextMonth: moment.Moment;
    page: number;
    pageSize: number;
    previousMonth: moment.Moment;
    sort: string;
    sortReverse: boolean;
    startDate: moment.Moment;
    totals: {
        currency: string;
        amount: number;
        convertedAmount: number;
        convertedCurrency: string;
    }[];
    totalContactCount: number;
    combinedTotal: number;
    watcher: () => void;
    watcher2: () => void;
    watcher4: () => void;
    watcher5: () => void;
    constructor(
        private $filter: ng.IFilterFunction,
        private $log: ng.ILogService,
        private $q: ng.IQService,
        private $rootScope: ng.IRootScopeService,
        private $stateParams: StateParams,
        private gettextCatalog: ng.gettext.gettextCatalog,
        private api: ApiService,
        private accounts: AccountsService,
        private contacts: ContactsService,
        private designationAccounts: DesignationAccountsService,
        private donations: DonationsService, // used in view
        private locale: LocaleService, // used in view
        private serverConstants: ServerConstantsService,
    ) {
        this.enableNext = false;
        this.data = [];
        this.listLoadCount = 0;
        this.loading = false;
        this.meta = {};
        this.page = 0;
        this.pageSize = 25;
        this.totalContactCount = 0;
        this.sort = 'donation_date';
        this.sortReverse = true;
        this.totals = [];
        this.combinedTotal = 0;

        this.watcher5 = $rootScope.$on('accountListUpdated', () => {
            this.load();
        });
    }
    $onInit(): void {
        if (!this.inContact) {
            this.startDate = defaultTo(moment().startOf('month'), this.$stateParams.startDate);
        }
        this.load();

        this.watcher = this.$rootScope.$on('donationUpdated', (e, donation) => {
            const foundIndex = findIndex({ id: donation.id }, this.data);
            const index = foundIndex > -1 ? foundIndex : this.data.length;
            this.data[index] = assign(this.data[index], donation);
        });

        this.watcher2 = this.$rootScope.$on('donationRemoved', (e, donation) => {
            this.data = pullAllBy('id', [donation], this.data);
            this.load();
        });

        this.watcher4 = this.$rootScope.$on('designationAccountSelectorChanged', () => {
            this.load();
        });
    }
    $onChanges(changesObj): void {
        if (changesObj.contact && !changesObj.contact.isFirstChange()) {
            this.load();
        }
    }
    $onDestroy(): void {
        this.watcher();
        this.watcher2();
        this.watcher4();
        this.watcher5();
    }
    load(): ng.IPromise<any> {
        this.meta = {};
        this.data = [];
        this.totals = [];
        this.combinedTotal = 0;
        this.listLoadCount++;
        const currentCount = angular.copy(this.listLoadCount);

        let params: any = {};

        if (this.designationAccounts.selected.length > 0) {
            params.designationAccountId = joinComma(this.designationAccounts.selected);
        }

        if (!this.inContact) {
            this.setMonths();
            params.startDate = this.startDate;
            params.endDate = this.endDate;
        } else if (this.contacts.current.donor_accounts) {
            if (this.contacts.current.donor_accounts.length === 0) {
                return this.$q.reject();
            }
            this.designationAccounts.load();
            params.donorAccountId = map('id', this.contacts.current.donor_accounts).join();
        }

        this.loading = true;
        return this.getDonations(params).then((data: any) => {
            /* istanbul ignore next */
            this.$log.debug('donations report', data);
            this.loading = false;
            if (this.loadedOutOfTurn(currentCount)) {
                return null;
            }
            this.meta = data.meta;
            this.data = this.mutateDataForSorts(data);
            this.data.forEach((donation) => this.sumCurrency(donation));
            return this.data;
        });
    }
    loadedOutOfTurn(currentCount): boolean {
        return currentCount !== this.listLoadCount;
    }
    mutateDataForSorts(data) {
        return map((donation) => {
            donation.amount = toNumber(donation.amount);
            donation.converted_amount = toNumber(donation.converted_amount);
            return donation;
        }, data);
    }
    setMonths(): void {
        this.previousMonth = moment(this.startDate).subtract(1, 'month');
        this.nextMonth = moment(this.startDate).add(1, 'month');
        this.endDate = moment(this.startDate).endOf('month');
        this.enableNext = moment(this.nextMonth).isBefore(moment());
    }
    gotoNextMonth(): void {
        this.startDate = this.nextMonth;
        this.load();
    }
    gotoPrevMonth(): void {
        this.startDate = this.previousMonth;
        this.load();
    }
    getDonations({
        startDate = null,
        endDate = null,
        donorAccountId = null,
        designationAccountId = null,
    } = {}): ng.IPromise<any> {
        let params: any = {
            per_page: 10000,
            fields: {
                contacts: 'name,pledge_frequency,pledge_frequency_display',
                designation_account: 'display_name,designation_number',
                donor_account: 'display_name,account_number',
                appeal: 'name',
            },
            filter: {},
            include: 'designation_account,donor_account,contact,appeal',
        };
        if (donorAccountId) {
            params.filter.donor_account_id = donorAccountId;
        }
        if (designationAccountId) {
            params.filter.designation_account_id = designationAccountId;
        }
        if (startDate && endDate && moment.isMoment(startDate) && moment.isMoment(endDate)) {
            const formattedStartDate = startDate.locale('en').format('YYYY-MM-DD');
            const formattedEndDate = endDate.locale('en').format('YYYY-MM-DD');
            params.filter.donation_date = `${formattedStartDate}..${formattedEndDate}`;
        }
        return this.api.get(`account_lists/${this.api.account_list_id}/donations`, params).then((data) => {
            this.$log.debug(`account_lists/${this.api.account_list_id}/donations`, data);
            return data;
        });
    }
    changeSort(field): void {
        if (this.sort === field) {
            this.sortReverse = !this.sortReverse;
            return;
        }
        this.sort = field;
        this.sortReverse = false;
    }
    sumCurrency(donation): void {
        const index = findIndex({ currency: donation.currency }, this.totals);
        if (index === -1) {
            this.totals.push({
                currency: donation.currency,
                amount: toNumber(donation.amount),
                convertedAmount: toNumber(donation.converted_amount),
                convertedCurrency: donation.converted_currency,
            });
        } else {
            this.totals[index].amount += toNumber(donation.amount);
            this.totals[index].convertedAmount += toNumber(donation.converted_amount);
        }
        this.combinedTotal += toNumber(donation.converted_amount);
    }
    showConvertedCurrency(): boolean {
        if (this.totals.length > 1) {
            return true;
        } else {
            return this.totals.length === 1 && get('[0].currency', this.totals) !== this.accounts.current.currency;
        }
    }
    conversionRateString(donation): string {
        const rate = (toNumber(donation.converted_amount) / toNumber(donation.amount)).toFixed(2);
        const date = this.$filter('date')(donation.donation_date, this.locale.dateTimeFormat);
        return this.gettextCatalog.getString(
            'Conversion rate of {{rate}} on {{date}}. This conversion is approximate.',
            { rate: rate, date: date },
        );
    }
    toCSV() {
        const columnHeaders = [
            this.gettextCatalog.getString('Date'),
            this.gettextCatalog.getString('Partner'),
            this.gettextCatalog.getString('Amount'),
            this.gettextCatalog.getString('Foreign Amount'),
            this.gettextCatalog.getString('Designation'),
            this.gettextCatalog.getString('Method'),
            this.gettextCatalog.getString('Appeal'),
        ];
        return reduce(
            (result, entry) => [
                ...result,
                [
                    moment(entry.donation_date).locale('en').format('MM/DD/YYYY'),
                    entry.donor_account.display_name,
                    `${entry.converted_amount} ${entry.converted_currency}`,
                    `${entry.amount} ${entry.currency}`,
                    entry.designation_account.display_name,
                    entry.payment_method,
                    entry.appeal?.name,
                ],
            ],
            [columnHeaders],
            this.data,
        );
    }
}

const Donations = {
    controller: DonationsController,
    template: require('./donations.html'),
    bindings: {
        inContact: '<',
    },
};

export default angular
    .module('mpdx.reports.donations.component', [
        uiRouter,
        'gettext',
        accounts,
        api,
        contacts,
        designationAccounts,
        donations,
        locale,
        serverConstants,
    ])
    .component('donations', Donations).name;
